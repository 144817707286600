import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import './MainPage.css'
import { useTranslation } from 'react-i18next';
import ButtonModal from '../../component/buttonModal/ButtonModal';
import Carousel from 'react-bootstrap/Carousel'
import RenderHTML from '../../component/renderHTML';
import arrow from '../../img/mainPage/arrow.png';
import star from '../../img/mainPage/star.png';
import time from '../../img/mainPage/time.png';

export default function MainPage(props) {
    const { t, } = useTranslation();
    return (
        <div id='divMain' className='mainPage'>
            <Container id='containerMain'>
                <div id='imgBack' alt='' />
                <Row id='rowTitleLabel'>
                    <Col id='colTitleLabelHR'>
                        <div id='contentMainPage'>
                            <div>
                                <h1>
                                    <div>
                                        <label className='titleLabelHR titleAboveHR'>
                                            {t('mainPage.touch')} 
                                        </label>
                                    </div>
                                    <div>
                                        <label className='titleLabelHR titleBelowHR'>
                                            <RenderHTML HTML={t('mainPage.lifeUtil')} />
                                        </label>
                                    </div>
                                    <div className='mobile'>
                                        <span className='explain'>Là phần mềm đa ngôn ngữ, được phát triển bởi công ty công nghệ đến từ Hàn Quốc chuyên về các sản phẩm quản lý tài chính, đáp ứng được mọi loại hình doanh nghiệp</span>
                                    </div>
                                </h1>
                            </div>
                        </div>
                        <br/>
                        <Row>
                            <Col md="3"></Col>
                            <Col md="6">
                                <Row>
                                    <Col>
                                        <div className='util'>
                                            <img src={star} style={{width:'40px',height:'40px',marginLeft:'auto',marginRight:'auto',position:'absolute',top:'14px',left:'10px'}}/>
                                            Chuyên nghiệp
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='util'>
                                            <img src={time} style={{width:'40px',height:'40px',marginLeft:'auto',marginRight:'auto',position:'absolute',top:'14px',left:'30px'}}/>
                                            <span className='time'>Tức thời</span>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='util'>
                                            <img src={arrow} style={{width:'40px',height:'40px',marginLeft:'auto',marginRight:'auto',position:'absolute',top:'14px',left:'10px'}}/>
                                            Hiệu quả
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="3"></Col>
                        </Row>
                        <Row>
                            <Col md="3"></Col>
                            <Col md="6">
                                <div>
                                    <ButtonModal name={'mainPage.registerBtn'} id={'buttonMainPageHR'}/>
                                </div>
                            </Col>
                            <Col md="3"></Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
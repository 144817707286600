// SwitchComponent.js
import React from 'react';
import HomePageERP from '../../infoerp/HomePage'; 
import HomePageHR from '../../infohr/HomePage'; 
import ProductAndDocumentation from '../../infoerpdownload/ProductAndDocumentation';

const SwitchComponent = ({ page }) => {
  switch (page) {
    case '/hr':
      return <div><HomePageHR/></div>;
    case '/download':
      return <div><ProductAndDocumentation/></div>
    default:
      return <div><HomePageERP/></div>
  }
};

export default SwitchComponent;
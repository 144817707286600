
import React, { useEffect, useState } from 'react';
import { Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { notification } from 'antd';

export default function LoadingButton(props) {

    let history = useHistory();

    const {t, } = useTranslation();

    useEffect(() => {
      if (props.isLoading) {
        openNotificationWithIcon('success');
      }
    }, [props.isLoading]);

    const handleClick = () => {
      props.onSave();
      //history.push("/thankyou");
    };

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type) => {
      api[type]({
        message: t('notification.message'),
        description:
        t('notification.description'),
      });

      history.push("/download");
    };

    return (
      <>
      {contextHolder}
      <Button
      // variant="primary"
        disabled={props.isLoading}
        onClick={!props.isLoading ? handleClick : null}
        id='btnRegister' className='registerButton' 
        download
      >
        {props.isLoading ? 
        <div className='btnLayout'>
            <label className='registerLabel'>{t('mainPage.registerPopupLoadingBtn')} </label>
        </div>
        : 
        <>
        <div  className="btnLayout">
            <div className="registerLabel">
                {t('mainPage.downloadNow')}
            </div>
        </div>
        </>
        }
      </Button>
      </>
    );
  }
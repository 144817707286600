// 
const cafe_24 = {
  InfoERP: {
    client_id: 'XfiFGcyvCy2LXYwJfV42GG',
    client_secret_key: 'ARzn675QM9GtfUfecIueWb',
    redirect_uri: "https://infoerpvn.com/cafe24",
    scope: "mall.read_product,mall.read_order,mall.read_store,mall.read_customer,mall.read_personal,mall.read_category,mall.read_salesreport,mall.read_translation",
    api_version: "2023-03-01",
    crsf_code: "random_string_csrf_code"
  },
}

export default cafe_24;

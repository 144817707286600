import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationVN from './i18n/vn/translation.json';
import translationVN_HR from './i18n/vn/translation_hr.json';
import translationEN from './i18n/en/translation.json';
import translationKr from './i18n/kr/translation.json';

// the translations
const resources = {
  vn: {
    translation: translationVN
  },
  vn_HR: { // Croatian translations
    translation: translationVN_HR
  },
  en: {
    translation: translationEN
  },
  kr: {
    translation: translationKr
  }
};

const i18nInstance = i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "vn",
    interpolation: {
      escapeValue: false // react already saves from xss
    }
  });

export { i18nInstance }; 
import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import './MainPage.css'
import { useTranslation } from 'react-i18next';
import ButtonDownload from '../../component/buttonDownload/ButtonDownload';
import ButtonModal from '../../component/buttonModal/ButtonModal';
import Carousel from 'react-bootstrap/Carousel'
import RenderHTML from '../../component/renderHTML';

export default function MainPage(props) {
    const { t, } = useTranslation();
    return (
        <div id='divMain' className='mainPage'>
            <Container id='containerMain'>
                <div id='imgBack' alt='' />
                <Row id='rowTitleLabel'>
                    <Col id='colTitleLabel'>
                        <div id='contentMainPage'>
                            <div>
                                <h1>
                                    <div>
                                        <label className='titleLabel titleAbove'>
                                            {t('mainPage.touch')} 
                                        </label>
                                    </div>
                                    <div>
                                        <label className='titleLabel titleBelow'>
                                            <RenderHTML HTML={t('mainPage.lifeUtil')} />
                                        </label>
                                    </div>
                                    <div className='mobile'>
                                        <span className='explain'>Là phần mềm đa ngôn ngữ, được phát triển bởi công ty công nghệ đến từ Hàn Quốc chuyên về các sản phẩm quản lý tài chính, đáp ứng được mọi loại hình doanh nghiệp</span>
                                    </div>
                                    <div>
                                        <label className='titleLabel titleBelowBelow'>
                                            {t('mainPage.solution')}
                                        </label>
                                    </div>
                                </h1>
                            </div>
                        </div>
                        <div>
                            {/* <ButtonDownload name={'mainPage.downloadBtn'} id={'buttonMainPage'} isEvents = {false}/> */}
                            <ButtonModal name={'mainPage.downloadBtn'} id={'buttonMainPage'} isEvents = {false}/>
                            {/* <ButtonModal name={'mainPage.registerBtn'} id={'buttonMainPage'} isEvents = {false}/> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
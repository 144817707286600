import { useTranslation } from 'react-i18next';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './AccountingSoftware.css'
import AccountingPoint_1 from '../../img/accountingSofware/accounting_point_1.png'
import AccountingPoint_2 from '../../img/accountingSofware/accounting_point_2.png'
import AccountingPoint_3 from '../../img/accountingSofware/accounting_point_3.png'
import AccountingPoint_4 from '../../img/accountingSofware/accounting_point_4.png'
import AccountingPoint_5 from '../../img/accountingSofware/accounting_point_5.png'
import AccountingPoint_6 from '../../img/accountingSofware/accounting_point_6.png'
import AccountingPoint_7 from '../../img/accountingSofware/accounting_point_7.png'
import AccountingPoint_8 from '../../img/accountingSofware/accounting_point_8.png'
import AccountingPoint_9_vn from '../../img/accountingSofware/accounting_point_9_vn.png'
import AccountingPoint_9_en from '../../img/accountingSofware/accounting_point_9_en.png'
import AccountingPoint_9_kr from '../../img/accountingSofware/accounting_point_9_kr.png'
import AccountingPoint_9_vn_mobile from '../../img/accountingSofware/accounting_point_9_vn_mobile.png'
// import AccountingPoint_9_en_mobile from '../../img/accountingSofware/accounting_point_9_en_mobile.png'
// import AccountingPoint_9_kr_mobile from '../../img/accountingSofware/accounting_point_9_kr_mobile.png'
import crossbar from '../../img/crossbar.png';
import i18next from 'i18next';
import RenderHTML from '../../component/renderHTML';
import ceo from '../../img/accountingSofware/CEO.png'
import hr from '../../img/accountingSofware/HR.png'
import teamManagement from '../../img/accountingSofware/team-management.png'
import delegate from '../../img/accountingSofware/delegate.png'

export default function AccountingSoftware() {
    const { t, } = useTranslation();

    const imgIntroductionMultiLanguage = () => {
        switch(i18next.language){
            case "vn_HR":
                return AccountingPoint_9_vn;
            case "en":
                return AccountingPoint_9_en;
            case "kr":
                return AccountingPoint_9_kr;
            default:
                break;
        } 
    }

    const imgIntroductioMobileMultiLanguage = () => {
        switch(i18next.language){
            case "vn_HR":
                return AccountingPoint_9_vn_mobile;
            // case "en":
            //     return AccountingPoint_9_en_mobile;
            // case "kr":
            //     return AccountingPoint_9_kr_mobile;
            default:
                break;
        } 
    }

    const ceo_bg = `#accounting_ceo_bg {
        background-image: url(${ceo});
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }`;

    const hr_bg = `#accounting_hr_bg {
        background-image: url(${hr});
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }`;

    return (
        <div id="accounting-sofware">
            <Row style={{ textAlign: 'center',marginBottom:'3%'}} className='web'>
                <Col lg={12}>
                    <img src={crossbar} style={{width:'144px'}}/><br/><br/>
                    <h2 className='' style={{color:'#152C7C',fontSize:'32px'}} dangerouslySetInnerHTML = {{ __html:t('accountingSoftware.title')}} ></h2>
                </Col>
            </Row>
            <Row>
                <Col id="accounting_ceo_bg" style={{height:'370px',color:'#F6F6F6',position:'relative',fontFamily: 'SVN-Gilroy'}}>
                        <style scoped>{ceo_bg}</style>
                        <img src={teamManagement} style={{position:'absolute',width:'80px',height:'80px',top:'20%',left:'5%'}}/>
                        <div style={{position:'absolute',top:'44%',left:'2%'}}>
                            <div style={{fontSize:'20px',fontFamily:'SVN-Gilroy',fontWeight:'700',textIndent:'20px'}}>
                                Đối với CEO
                            </div>
                            <ul>
                                <li><b>Dễ dàng nắm bắt toàn cảnh</b> tình hình nhân sự công ty <br/> mọi lúc mọi nơi</li>
                                <li><b>Phân quyền quản lý</b>, tránh rủi ro thất thoát thông tin</li>
                                <li><b>Kiểm soát biến động nhân sự</b>, kịp thời dưa ra các quyết <br/> định điều chỉnh</li>
                            </ul>
                        </div>
                </Col>
                <Col id="accounting_hr_bg" style={{height:'370px',color:'#152C7C',position:'relative',fontFamily: 'SVN-Gilroy'}}>
                        <style scoped>{hr_bg}</style>
                        <img src={delegate} style={{position:'absolute',width:'80px',height:'80px',top:'20%',left:'5%'}}/>
                        <div style={{position:'absolute',top:'44%',left:'2%'}}>
                            <div style={{fontSize:'20px',fontFamily:'SVN-Gilroy',fontWeight:'700',textIndent:'20px'}}>
                                Đối với HR
                            </div>
                            <ul>
                                <li><b>Báo cáo nhân sự tiền lương tự động,</b> kịp thời</li>
                                <li><b>Quản lý chuyên nghiệp</b> hồ sơ ứng viên, nhân viên</li>
                                <li><b>Linh động tính lương</b> theo nhiều cách</li>
                            </ul>
                        </div>
                </Col>
            </Row>
        </div>
    )
}
import React from 'react';
import accbot_img from '../img/download/accounting_bot.png';
import infoerp_img from '../img/download/img1.jpg';
import infoagency_img from '../img/download/img2.jpg';
import accbot_setup from '../file/AccountingBot_Setup.zip';
import info_agency_setup from '../file/infoERP_Agency_Setup.zip';
import infoerp_setup from '../file/infoERP_Setup.zip';
import s_infoerp_setup from '../file/S_INFO_ERP.rar';
import { Link } from 'react-router-dom';

const ProductAndDocumentation = () => {
    return (
            <div style={{ marginLeft: '20px' }}>
                <br />
                <div style={{backgroundColor:'#007bff',color:'white',padding:'12px',borderRadius:'.35rem',textAlign:'center',width:'500px',margin:'0 auto'}}>InfoERP Software Download Service</div>

                <div className="row card-body" style={{width:'800px',margin:'0 auto'}}>
                    <div className="col-sm-6">
                        <div className="card">
                            <div className="card-body text-center">
                                <h5 className="card-title">InfoERP</h5>
                                <img src={infoerp_img} alt="Product 1" style={{ width: '90px', height: '90px' }} />
                                <br />
                                <b>
                                    <Link
                                        to={infoerp_setup}
                                        download="infoERP_Setup"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Tải phần mềm InfoERP <br/> (Accounting Program)

                                    </Link>
                                </b>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="card">
                            <div className="card-body text-center">
                                <h5 className="card-title">S-InfoERP</h5>
                                <img src={infoerp_img} alt="Product 4" style={{ width: '90px', height: '90px' }} />
                                <br />
                                <b>
                                    <Link
                                        to={s_infoerp_setup}
                                        download="S_INFO_ERP.rar"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Tải phần mềm S-InfoERP <br/> (HR, MES Program)
                                    </Link>
                                </b>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div className="row card-body" style={{width:'800px',margin:'0 auto'}}>
                <div className="col-sm-6">
                        <div className="card">
                            <div className="card-body text-center">
                                <h5 className="card-title">InfoERP Agency</h5>
                                <img src={infoagency_img} alt="Product 2" style={{ width: '90px', height: '90px' }} />
                                <br />
                                <b>
                                    <Link
                                        to={info_agency_setup}
                                        download="infoERP_Agency_Setup"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Tải phần mềm InfoERP Agency <br/> (Accounting Program for Agency)
                                    </Link>
                                </b>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="card">
                            <div className="card-body text-center">
                                <h5 className="card-title">Accounting Bot</h5>
                                <img src={accbot_img} alt="Product 3" style={{ width: '90px', height: '90px' }} />
                                <br />
                                <b>
                                    <Link
                                        to={accbot_setup}
                                        download="AccountingBot_Setup"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Tải phần mềm Accounting Bot <br/> (Tax Automation)
                                    </Link>
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default ProductAndDocumentation;

import './Support.css'
import { Container, Row, Col } from 'react-bootstrap'
import crossbar from '../../img/crossbar.png';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';

export default function Support(props) {
    const { t, } = useTranslation();

    const text = `
    <div>123</div>
    A dog is a type of domesticated animal.
    Known for its loyalty and faithfulness,
    it can be found as a welcome guest in many households across the world.
  `;

    return (
        <div id="story" ref={props.refer}>
            <Container>
                    <Row style={{ textAlign: 'center' }} className='web'>
                        <Col lg={12}>
                            <img src={crossbar} style={{width:'144px'}}/><br/><br/>
                            <h2 className='' style={{color:'#152C7C',fontSize:'32px'}} dangerouslySetInnerHTML = {{ __html:t('support.title')}} ></h2>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <div style={{fontFamily:'SVN-Gilroy',fontSize:'16px',lineHeight:'24px',fontWeight:'500',color:'#000000'}}>
                            InfoHR cung cấp giải pháp HRM giúp doanh nghiệp quản lý nhân sự - chấm công - tính lương một cách tự động, chuyên nghiệp, chính xác. InfoHR đáp ứng nhu cầu của mọi loại hình doanh nghiệp, tự động hóa nghiệp vụ quản lý, hệ thống báo cáo đầy đủ, đa dạng, bảo mật dữ liệu mạnh mẽ.
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md="6">
                            <Collapse className='supportContent' expandIconPosition="end" items={[{ key: '1', label: '1. Tuyển dụng', children: 
                            <div>
                                <ul>
                                    <li>Quy trình tuyển dụng</li>
                                    <li>Mẫu thống kê nhu cầu tuyển dụng</li>
                                    <li>Mẫu JD theo ngành nghề</li>
                                    <li>Tổng hợp bài test tuyển dụng</li>
                                    <li>Kỹ năng phỏng vấn và đọc vị ứng viên</li>
                                    <li>Quy trình và biểu mẫu Onboarding</li>
                                </ul>
                            </div> 
                            }]}/>
                            <Collapse className='supportContent' expandIconPosition="end" items={[{ key: '1', label: '2. Hành chính văn phòng', children: 
                            <div>
                                 <ul>
                                     <li>Quy trình kiểm soát tài liệu hồ sơ</li>
                                     <li>Quy trình quản lý thông tin đi đến</li>
                                     <li>Quy trình bảo trì thiết bị văn phòng</li>
                                     <li>Quy trình lập kế hoạch báo cáo thống kê</li>
                                     <li>Mẫu kế hoạch công việc Excel</li>
                                     <li>Bảng theo dõi thời gian làm việc</li>
                                     <li>Tổng hợp mẫu biên bản, đơn xin nghỉ phép, nghỉ không lương,..v.v</li>
                                 </ul>
                            </div> 
                            }]}/>
                            <Collapse className='supportContent' expandIconPosition="end" items={[{ key: '1', label: '3. Hợp đồng và bảo hiểm', children: 
                            <div>
                                <ul>
                                    <li>Biểu mẫu hợp đồng lao động</li>
                                    <li>Biểu mẫu bảo hiểm xã hội</li>
                                    <li>Quy trình theo hợp đồng chính thức</li>
                                    <li>Mẫu hợp đồng khoán việc</li>
                                    <li>Biên lai thanh lý HDLD</li>
                                    <li>Quyết định chấm dứt HDLD</li>
                                </ul>
                           </div> 
                            }]}/>
                            <Collapse className='supportContent'  expandIconPosition="end" items={[{ key: '1', label: '4. Đánh giá nhân sự', children: 
                            <div>
                                <ul>
                                    <li>Mẫu đánh giá hiệu suất nhân viên</li>
                                    <li>Mẫu đánh giá công việc theo kỳ</li>
                                    <li>Mẫu đánh giá quản lý</li>
                                    <li>Mẫu đánh giá theo nhóm</li>
                                    <li>Mẫu kế hoạch phát triển nghề nghiệp</li>
                                    <li>Mẫu kế hoạch đào tạo nhân viên</li>
                                    <li>Mẫu đánh giá đồng nghiệp ngang hàng</li>
                                </ul>
                            </div> 
                            }]}/>
                            <Collapse className='supportContent' expandIconPosition="end" items={[{ key: '1', label: '5. Từ vấn chiến lược HRBP', children: 
                            <div>
                                <ul>
                                    <li>Mẫu quyết định nhân sự</li>
                                    <li>Mẫu báo cáo nhân sự</li>
                                    <li>Tổng hợp mẫu quy trình/quy định</li>
                                    <li>Mẫu khảo sát mức độ hài lòng của nhân viên</li>
                                </ul>
                            </div> 
                            }]}/>
                        </Col>
                        <Col md="6">
                            <Collapse className='supportContent' expandIconPosition="end" items={[{ key: '1', label: '6. Chấm công', children: 
                            <div>
                                <ul>
                                    <li>Bảng chấm công theo ca</li>
                                    <li>Bảng chấm công theo giờ</li> 
                                    <li>Bảng chấm công theo ngày</li>
                                    <li>Bảng chấm công theo tuần</li>
                                    <li>Bảng chấm công làm thêm giờ</li>
                                    <li>Bảng chấm công trên Word</li> 
                                    <li>DASHBOARD báo cáo chấm công tự động</li>
                                    <li>Quy định về thời gian làm việc</li>
                                    <li>Chính sách ngày nghỉ của nhân viên</li>
                                </ul>
                            </div> 
                            }]}/>
                            <Collapse className='supportContent' expandIconPosition="end" items={[{ key: '1', label: '7. Tiền lương', children: 
                            <div>
                                <ul>
                                    <li>Mẫu bảng lương theo số công</li>
                                    <li>Mẫu bảng lương theo doanh số</li> 
                                    <li>Mẫu bảng lương theo sản phẩm</li>
                                    <li>Mẫu bảng lương 3P</li>
                                    <li>Mẫu bảng lương Cộng tác viên</li>
                                    <li>Quy chế tiền lương</li> 
                                    <li>Đơn đề xuất tăng lương</li>
                                    <li>Mẫu quyết định giảm lương</li>
                                    <li>Mẫu thông báo tăng lương</li>
                                </ul>
                            </div> 
                            }]}/>
                            <Collapse className='supportContent' expandIconPosition="end" items={[{ key: '1', label: '8. Quản lý hồ sơ nhân sự', children: 
                            <div>
                                <ul>
                                    <li>Quy trình và hồ sơ đánh giá nội bộ</li>
                                    <li>Quy trình và hồ sơ đào tạo nhân sự</li> 
                                    <li>Quy trình và hồ sơ nâng lương, kỷ luật</li>
                                    <li>Quy trình và hồ sơ tuyển dụng</li>
                                    <li>Mẫu bổ nhiệm, miễn nhiệm, kỷ luật, sa thải Nhân sự</li>
                                    <li>Biểu mẫu quản lý nhân sự bằng Excel</li> 
                                    <li>Mẫu sổ tay nhân viên</li>
                                </ul>
                            </div> 
                            }]}/>
                            <Collapse className='supportContent' expandIconPosition="end" items={[{ key: '1', label: '9. Chuyển đổi số phòng nhân sự', children:
                            <div>
                                <ul>
                                    <li>Xu hướng quản trị nhân sự 2023</li>
                                    <li>Cẩm nang chuyển đổi số cho Nhân sự</li> 
                                    <li>Giải pháp chuyển đổi số Chấm công cho Doanh nghiệp vừa và lớn</li>
                                    <li>Giải pháp chuyển đổi số Tuyển dụng cho Doanh nghiệp vừa và lớn</li>
                                    <li>Giải pháp khắc phục lãng phí thao tác cho Doanh nghiệp vừa và lớn</li>
                                </ul>
                            </div> 
                            }]}/>
                        </Col>
                    </Row>
            </Container>
        </div>
    )
}
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './introduction.css'
import { useTranslation } from 'react-i18next';
import ImgCom from '../../component/imgCom/ImgCom';
import crossbar from '../../img/crossbar.png';
import introductioon_vn from '../../img/introduction/introduction_hr_vn.png';
import introduction_kr from '../../img/introduction/introduction_kr.png';
import introduction_en from '../../img/introduction/introduction_en.png';
import introductioon_vn_mobile from '../../img/introduction/introduction_vn_mobile.png';
import RenderHTML from '../../component/renderHTML';
import i18next from 'i18next';
import lighting from '../../img/introduction/lighting.png';

export default function Introduction(props) {
   
    const {t} = useTranslation();

    const imgIntroductionMultiLanguage = () => {
        switch(i18next.language){
            case "vn_HR":
                return introductioon_vn;
            case "en":
                return introduction_en;
            case "kr":
                return introduction_kr;
            default:
                break;
        } 
    }

    const imgIntroductionMobileMultiLanguage = () => {
        switch(i18next.language){
            case "vn_HR":
                return introductioon_vn_mobile;
            default:
                break;
        } 
    }

    return (
        <div id='introduction' style={{margin:'95px 0'}} ref={props.refer}>
            <Row>
                <Col md="6">
                    <img src={crossbar} style={{width:'144px'}}/>
                    <br/><br/>
                    <div style={{color:'#152C7C',fontFamily:'SVN-Gilroy',fontWeight:'700',fontSize:'32px',lineHeight:'36px'}}>Vì sao khách hàng nên <br/> lựa chọn giải pháp HRM của InfoHR?</div>
                    <br/>
                    <ul style={{fontFamily:'SVN-Gilroy',fontWeight:'500',fontSize:'16px',lineHeight:'32px'}}>
                        <li>Tích hợp <span style={{fontWeight:'700'}}>đa dạng hình thức chấm công</span></li>
                        <li><span style={{fontWeight:'700'}}>Tự động tính lương</span>, BHXH, thuế TNCN</li>
                        <li><span style={{fontWeight:'700'}}>Kết nối Kế toán </span>để xử lý công việc kế toán tiền lương</li>
                        <li><span style={{fontWeight:'700'}}>Quản lý nhân sự </span>- lạo động tập trung</li>
                        <li><span style={{fontWeight:'700'}}>Hệ thông báo cáo</span> đầy đủ, đa dạng</li>
                        <li><span style={{fontWeight:'700'}}>Dễ dàng kiểm soát</span> biến động nhân sự</li>
                    </ul>
                    <div style={{color:'#152C7C',fontFamily:'SVN-Gilroy',textTransform:'uppercase',fontWeight:'700',fontSize:'16px',lineHeight:'24px',fontStyle:'italic'}}>
                        <img src={lighting} style={{width:'17px',height:'17px'}} />
                        Sử dụng miễn phí tất cả tính năng từ 01/08/2023 - 31/12/2023
                    </div>
                </Col>
                <Col md="6">
                    <ImgCom src={imgIntroductionMultiLanguage()} alt='' className='img-intro-hr'/>
                </Col>
            </Row>

            {/* <div style={{position:'relative'}}>
                <div className='backgroundTitle'>{t("intro.backgroundTitle")}</div>
                <h2 className='labelTitle' style={{marginTop:'63.66px',marginBottom:'24px'}}>
                    <RenderHTML HTML={t('intro.title')} />
                </h2>
            </div>
            <div style={{textAlign:'center',fontWeight:'bold',fontSize:'18px'}}>
                {t('intro.subTitle')}
            </div>
            <br/>
            <Container >
                <Row >
                    <Col className='introduction-content'>
                    <div>
                        <RenderHTML HTML={t('introduction.detail.1')} />
                    </div>
                    <div style={{textAlign:'center'}}>
                        <RenderHTML HTML={t('introduction.detail.2')} />
                    </div>
                    </Col>
                </Row>
            </Container>
            
            <div className='mobile'>
                <div style={{textAlign:'center',marginTop:49}}>
                    <ImgCom src={imgIntroductionMobileMultiLanguage()} alt='' className='img-intro' />
                </div>
            </div>
            <div className='web'>
                <div style={{textAlign:'center',marginTop:49}}>
                    <ImgCom src={imgIntroductionMultiLanguage()} alt='' className='img-intro' />
                </div>
            </div> */}
        </div>
    )
}
import React, { Component } from 'react';
import FormInput from '../formInput/FormInput';
import imgBack from '../../img/register/registerImage.png'
import ImgCom from '../imgCom/ImgCom';
import { withTranslation } from 'react-i18next';
import './RegisterForm.css'
import Popup from '../popup/popup';
import validate from '../../util/validateUtil';
import sendEmail from '../../service/sendEmail';
import saveGuest from '../../service/saveGuest'
import LoadingButton from '../../component/loadingButton/LoadingButton'

let initialState = {
    name: '',
    phone: '',
    email: '',
    area: '',
    tax: '',
    numberRoom: '',
    errorName: null,
    errorPhone: null,
    errorEmail: null,
    errorArea: null,
    errorRoom: null,
    errorTax:null,
    isFail: false,
    show: false,
    isLoading: false,
    currentPathName : '/infoerp',
    time: new Date().toLocaleString('en-GB', { timeZone: 'UTC' })
}

class RegisterFormClass extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;

        this.changeValue = this.changeValue.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    changeValue(params) {
        this.setState({
            [params.name]: params.value
        });
    }

    onSave = () => {
        let isShow = 
            !validate.isEmpty(this.state.name) && 
            !validate.isEmpty(this.state.phone)
            // && !validate.isEmpty(this.state.email)
            // && !validate.isEmpty(this.state.area)
            // && !validate.isEmpty(this.state.numberRoom)
            && validate.isPhone(this.state.phone)
            //&& validate.isEmail(this.state.email)
            // && !validate.isEmpty(this.state.tax);
        this.setState({
            errorName: validate.isEmpty(this.state.name) ? 'empty' : null,
            errorPhone: validate.isEmpty(this.state.phone) ? 'empty' : (!validate.isPhone(this.state.phone) ? 'invalid' : null),
            //errorEmail: validate.isEmpty(this.state.email) ? 'empty' : (!validate.isEmail(this.state.email) ? 'invalid' : null),
            // errorArea: validate.isEmpty(this.state.area) ? 'empty' : null,
            // errorRoom: validate.isEmpty(this.state.numberRoom) ? 'empty' : null,
            // errorTax: validate.isEmpty(this.state.tax) ? 'empty' : (!validate.isTax(this.state.tax) ? 'invalid' : null),
        })

        if (isShow){
            this.setState({
                isLoading: true
            })

            if(this.props.pathName == "/cafe24") {
                this.state.currentPathName = this.props.pathName;
            }

            //this.onSendEmail();
            this.onSaveRegisteredUsers();
            // console.log(this.state.isLoading);
            const fileUrl = 'https://infoerpvn.com:9100/admin/downloadInfoERP';
            this.downloadFileFromUrl(fileUrl, 'infoERP_Setup.zip');

        }     
    }

    downloadFileFromUrl = (url, fileName) => {
        fetch(url)
          .then(response => response.blob())
          .then(blob => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName || 'downloaded-file';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.onClose();
          })
          .catch(error => console.error('Error downloading file:', error));
    }

    onSendEmail() {
        this.setState({
            isFail: false,
            show: true,
            isLoading : false
        })
        sendEmail({ name: this.state.name, phone: this.state.phone, email: this.state.email, tax: this.state.tax}).then(result => {
        }).catch(error => {
        })
    }

   

    onSaveRegisteredUsers() {
            saveGuest.insertGuest({ name: this.state.name, phone: this.state.phone, email: this.state.email, tax: this.state.tax, sourceFrom : this.state.currentPathName}).then(result => {
            
            }).catch(error => {
              
            })
    }

    onClose() {
        if (this.props.isModal && !this.state.isFail) {
            this.props.onCloseModal();
        }

        this.setState(initialState);
    }

    

    render() {
        const { t } = this.props;

        return (
            <>
            <div className='regLayoutDiv'>
                <div id={this.props.id} className='registerDiv'>
                    <ImgCom className='regImgBack' alt='' src={imgBack} />
                    <div id='registerForm'>
                        <div style={{ textAlign: 'center' }} className='mobile'>
                            <label className='regTitle'>{this.props.isModal == false ? t('register.title') : t("mainPage.registerPopupTitle") }</label>
                        </div>
                        <div className='regTitle web'>
                            {this.props.isModal == false ? 
                             <div>
                             <label>{ this.props.isModal == false ? t('register.webTitle1') : ""}</label>
                            </div>
                            :
                            <></>
                            }
                           
                           {t('register.webTitle2') == "" ? <></> :
                            <div>
                            <label>{ this.props.isModal == false ? t('register.webTitle2') : t("mainPage.registerPopupTitle")}</label>
                            </div>
                           }
                        </div>
                        <div style={{ maxWidth: 1167, margin: 'auto' }}>
                            <div>
                                {
                                    this.props.isModal == false ? 
                                    <div style={{display:'flex',marginBottom:'29px'}}>
                                        <ul style={{color:'#7DFFFD',margin:'auto',fontSize:'12px'}}>
                                            <li> {t('register.note1')}</li>
                                            {t('register.note2') !== "" && <li>{t('register.note2')}</li>}
                                            {/* <li> {t('register.note3')}</li> */}
                                        </ul>
                                    </div>
                                    :
                                    <></>
                                }
                                
                                <form>
                                    <FormInput name={'register.form.name'} type={'name'} format = {'text'} maxLength = {'300'} value={this.state.name} onChange={this.changeValue} errorName={'errorName'} error={this.state.errorName} placeholder={t('placeholder.name')}/>
                                    {/* <FormInput name={'register.form.tax'} type={'tax'} format = {'text'} minLength = {'10'} maxLength = {'14'} value={this.state.tax} onChange={this.changeValue} errorName={'errorTax'} error={this.state.errorTax}  placeholder={t('placeholder.tax')}/> */}
                                    {/* <FormInput name={'register.form.email'} type={'email'} format = {'text'} maxLength = {'100'} value={this.state.email} onChange={this.changeValue} errorName={'errorEmail'} error={this.state.errorEmail}  placeholder={t('placeholder.email')}/> */}
                                    <FormInput name={'register.form.phone'} type={'phone'} format = {'number'} maxLength = {'10'} value={this.state.phone} onChange={this.changeValue} errorName={'errorPhone'} error={this.state.errorPhone}  placeholder={t('placeholder.phone')}/>
                                    <div style={{display:'flex',justifyContent:'center' }} className="buttonRegisterDiv">
                                        <LoadingButton onSave = {this.onSave} onClose={this.onClose} isLoading = {this.state.isLoading} isModal = {this.props.isModal} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Popup onClose={this.onClose} show={this.state.show} isFail={this.state.isFail} /> */}
            </div>
            </>
        )
    }
}

export default withTranslation()(RegisterFormClass);
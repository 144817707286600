import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AdvantageItem from '../../component/advantageItem/AdvantageItem';
import webImgWeb from '../../img/advantage/ui_ux_friendly.png';
import botImgWeb from '../../img/advantage/accounting-bot.png';
import appLanguageImgWeb_vn from '../../img/advantage/da_ngon_ngu_vn.png';
import appLanguageImgWeb_en from '../../img/advantage/da_ngon_ngu_en.png';
import appLanguageImgWeb_kr from '../../img/advantage/da_ngon_ngu_kr.png';
import appResImgWeb from '../../img/advantage/kiem_tra_doi_chieu_1.png';
import appResImgWeb2_vn from '../../img/advantage/kiem_tra_doi_chieu_2_vn.png'
import appResImgWeb2_en from '../../img/advantage/kiem_tra_doi_chieu_2_en.png'
import appResImgWeb2_kr from '../../img/advantage/kiem_tra_doi_chieu_2_kr.png'
import appManImgWeb_vn from '../../img/advantage/tich_hop_firm_bank_vn.png';
import appManImgWeb_en from '../../img/advantage/tich_hop_firm_bank_en.png';
import appManImgWeb_kr from '../../img/advantage/tich_hop_firm_bank_kr.png';
import AdvantageItemWebRight from '../../component/advantageItem/AdvantageItemWebRight';
import AdvantageItemWebLeft from '../../component/advantageItem/AdvantageItemWebLeft';
import { useTranslation } from "react-i18next";
import './Advantage.css';
import ImgCom from '../../component/imgCom/ImgCom';
import i18next from 'i18next';
import crossbar from '../../img/crossbar.png';
import bg_advantage from '../../img/advantage/background_advantage.png';

import layer from '../../img/advantage/Layer.png';
import cyberSecurity from '../../img/advantage/cyber-security.png';
import startup from '../../img/advantage/startup.png';
import valueChain from '../../img/advantage/value-chain.png';

export default function Advantage(props) {
    const { t, } = useTranslation();

    const imgAppManMultiLanguage = () => {
        switch(i18next.language){
            case "vn_HR":
                return appManImgWeb_vn;
            case "en":
                return appManImgWeb_en;
            case "kr":
                return appManImgWeb_kr;
            default:
                break;
        } 
    }

    const imgAppLanguageMultiLanguage = () => {
        switch(i18next.language){
            case "vn_HR":
                return appLanguageImgWeb_vn;
            case "en":
                return appLanguageImgWeb_en;
            case "kr":
                return appLanguageImgWeb_kr;
            default:
                break;
        } 
    }

    const imgAppResMultiLanguage = () => {
        switch(i18next.language){
            case "vn_HR":
                return appResImgWeb2_vn;
            case "en":
                return appResImgWeb2_en;
            case "kr":
                return appResImgWeb2_kr;
            default:
                break;
        } 
    }

    const accountingBot = { 
        imageUrl: botImgWeb,
        subTitle: '',
        titleUp: 'advantage.accountingBotTitle',
        titleDown: 'advantage.webTitle2',
        content: 'advantage.accountingBotDetail',
        content_2 : 'advantage.accountingBotDetail1' ,
        content_3 : 'advantage.accountingBotDetail2' ,
    }

    const web = { 
        imageUrl: webImgWeb,
        subTitle: '',
        titleUp: 'advantage.webTitle1',
        titleDown: 'advantage.webTitle2',
        content: 'advantage.webDetail',
        content_2 : 'Tự động kiểm soát các thủ tục nhân sự: Nhân viên hết hạn hợp đồng, sinh nhật, quá trình công tác, khen thưởng, kỷ luật',
        content_3 : 'Báo cáo tự động tình hình nhân sự công ty: Số lượng nhân sự phòng ban, tình hình biến động nhân sự,…'
    }
    
    const appMan = { 
        imageUrl: imgAppManMultiLanguage(),
        subTitle: 'advantage.appManSubTitle',
        titleUp: 'advantage.appManTitle1', 
        titleDown: 'advantage.appManTitle2', 
        content: 'advantage.appManDetail', 
        content_2 : 'advantage.appManDetail2',
        content_3 : 'advantage.appManDetail3',
    }
    
    const appRes = { 
        imageUrl: appResImgWeb,
        imageUrl_2 : imgAppResMultiLanguage(),
        subTitle: 'advantage.appResSubTitle',
        titleUp: 'advantage.appResTitle1',
        titleDown: 'advantage.appResTitle2', 
        content: 'advantage.appResDetail' 
    }

    const appLanguage = { 
        imageUrl: imgAppLanguageMultiLanguage(),
        subTitle: 'advantage.appLanguageSubTitle',
        titleUp: 'advantage.appLanguageTitle1',
        titleDown: 'advantage.appLanguageTitle2', 
        content: 'advantage.appLanguageDetail' 
    }

    const backgroundImage = `
        #advLabelWeb {
            background-image: url(${bg_advantage}) !important,
        }
    `;

    return (
        <div id="advantage" ref={props.refer}>
            <div id="advantageImgBack"></div>
            <div>
                <Container>
                    {/* <Row>
                        <Col lg={12} id='advLabelWeb'>
                            <div>
                            
                                <img src={crossbar} style={{width:'144px'}}/><br/><br/>
                                <h2 className='labelTitle'>{t("advantage.title")}</h2>
                            </div>
                        </Col>
                    </Row> */}
                    <Row style={{ textAlign: 'center',marginBottom:'3%' }} className='web'>
                        <Col lg={12}>
                            <img src={crossbar} style={{width:'144px'}}/><br/><br/>
                            <h2 className='' style={{color:'#152C7C',fontSize:'32px'}} dangerouslySetInnerHTML = {{ __html:t('advantage.title')}} ></h2>
                        </Col>
                    </Row>
                </Container>
                <div className='mobile'>
                    <AdvantageItem value={accountingBot} id={'itemBot'} />
                    <AdvantageItem value={web} id={'itemWeb'} />
                    <AdvantageItem value={appMan} id={'itemMan'} />
                    <AdvantageItem value={appRes} id={'itemRes'} />
                    <AdvantageItem value={appLanguage} id={'itemLanguage'} />
                </div>
                <div className='web' style={{fontFamily:'SVN-Gilroy'}}>
                    <Row>
                        <Col md="3">
                            <img src={valueChain} style={{width:'64px',height:'64px',marginLeft:'auto',marginRight:'auto',display:'block'}}/><br/>
                            <div>1. Tối ưu chi phí và nguồn lực quản lý doanh nghiệp</div>
                        </Col>
                        <Col md="3">
                            <img src={startup} style={{width:'64px',height:'64px',marginLeft:'auto',marginRight:'auto',display:'block'}}/><br/>
                            <div>2. Nâng cao năng suất và hiệu quả công việc</div>
                        </Col>
                        <Col md="3">
                            <img src={layer} style={{width:'64px',height:'64px',marginLeft:'auto',marginRight:'auto',display:'block'}}/><br/>
                            <div>3. Hiện đại hóa quy trình quản lý doanh nghiệp</div>
                        </Col>
                        <Col md="3">
                            <img src={cyberSecurity} style={{width:'64px',height:'64px',marginLeft:'auto',marginRight:'auto',display:'block'}}/><br/>
                            <div>4. Bảo mật dữ liệu tuyệt đối</div>
                        </Col>
                    </Row>
                    <div style={{ marginTop: 0,marginBottom: 238 }}></div>
                </div>
            </div>
        </div>
    )
}
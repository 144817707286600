import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import i18n from "i18next";
import SwitchComponent from './component/SwitchComponent/SwitchComponent.js';

function HomePageMain() {
    const location = useLocation();
    
    const updateLanguage = (pathname) => {
        const isCroatianTranslation = pathname === "/hr";
    
        if (isCroatianTranslation) {
          i18n.changeLanguage("vn_HR"); // Switch to Croatian translation
        } else {
          i18n.changeLanguage("vn"); // Default to Vietnamese translation
        }
      };

    // Call the updateLanguage function when the component mounts to update the translation based on the current location
    useEffect(() => {
        updateLanguage(location.pathname);
    }, [location.pathname]);

    return (
        <SwitchComponent page={location.pathname } />
    )
}

export default HomePageMain;
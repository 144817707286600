function isEmpty(params) {
    return params === null || params === '' || params === undefined;
}

function isEmail(params) {
    const regExp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regExp.test(params);
}

function isTax(params) {
    const regExp = /^[0-9-+()]*$/;///^\d+$/;
    return regExp.test(params);
}

function isPhone(params) {
    const regExp = /^(\+\d{1,3}\s?)?(\d{10})$/;
    return regExp.test(params);
}

function isZeroTen(number){
    if (number < 10){
        number = '0' + number;
    }

    return number;
}

function isMinLength(mst) {
    var result = true;

    if(mst.length < 10) {
        result = false;
    }

    return result;
}

const validate = {isEmpty, isEmail, isPhone, isZeroTen,isTax,isMinLength};
export default validate;

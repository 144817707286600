import './Story.css'
import { Container, Row, Col } from 'react-bootstrap'
import crossbar from '../../img/crossbar.png';
import story from '../../img/story/Story.png';
import vector from '../../img/story/Vector.png';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'antd';

export default function Story(props) {
    const { t, } = useTranslation();

    const contentStyle = {
        height: '300px',
        color: '#000000',
        fontFamily: 'SVN-Gilroy',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20px',
        paddingTop: '20px',
        textAlign: 'justify',
    };

    return (
        <div id="story" ref={props.refer} style={{marginBottom:'50px'}}>
            <Container>
                <Row style={{ textAlign: 'center' }} className='web'>
                    <Col lg={12}>
                        <img src={crossbar} style={{ width: '144px' }} /><br /><br />
                        <h2 className='' style={{ color: '#152C7C', fontSize: '32px' }} dangerouslySetInnerHTML={{ __html: t('story.title') }} ></h2>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md="6">
                        <img src={story} style={{ width: '367px', height: '289px', display: 'block', marginLeft: 'auto', marginRight: '8%' }} />
                    </Col>
                    <Col md="6">
                        <Carousel autoplay>
                            <div>
                                <div style={{ fontFamily: 'SVN-Gilroy', fontSize: '20px', fontWeight: '700', lineHeight: '22px' }}>Khách hàng 1 - Chị B - HR công ty C</div>
                                <Row>
                                    <Col md="2">
                                        <img src={vector} style={{ width: '70px', height: '58px', marginTop: '25px' }} />
                                    </Col>
                                    <Col md="10">
                                        <div style={contentStyle}>
                                            "Sau nhiều lần thay đổi các giải pháp HRM khác nhau, InfoHR là lựa chọn phù hợp nhất với mình. Giao diện dễ sử dụng, tính năng đầy đủ và hiện đại. Đây đúng là trợ lý ảo đắc lực của HR."
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <div style={{ fontFamily: 'SVN-Gilroy', fontSize: '20px', fontWeight: '700', lineHeight: '22px' }}>Khách hàng 2 - Chị X - trưởng phòng Hành chính - nhân sự công ty Y</div>
                                <Row>
                                    <Col md="2">
                                        <img src={vector} style={{ width: '70px', height: '58px', marginTop: '25px' }} />
                                    </Col>
                                    <Col md="10">
                                        <div style={contentStyle}>
                                            "Giải pháp HRM của InfoHR hội tụ đầy đủ những yếu tố tôi cần: đơn giản - chính xác - hiệu quả. Thay vì cả phòng nhân sự nhập liệu, tìm hồ sơ, giấy tờ, làm báo cáo thì giờ đây mọi chuyện trở nên dễ dàng hơn nhờ phần mềm này. InfoHR không chỉ giúp tiết kiệm chi phí, tối ưu nguồn nhân lực cho doanh nghiệp mà còn giúp HR "nhẹ gánh" công việc.
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <div style={{ fontFamily: 'SVN-Gilroy', fontSize: '20px', fontWeight: '700', lineHeight: '22px' }}>Khách hàng 3 - Anh A - kế toán công ty B</div>
                                <Row>
                                    <Col md="2">
                                        <img src={vector} style={{ width: '70px', height: '58px', marginTop: '25px' }} />
                                    </Col>
                                    <Col md="10">
                                        <div style={contentStyle}>
                                            "Từ ngày sử dụng InfoHR, chúng tôi không còn lo sợ tính sai công và lương cho nhân viên nữa. Không những vậy, lãnh đạo có thể nắm bắt trực tiếp tình hình nhân sự công ty mọi lúc mọi nơi."
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import solutionImg_Hr_1 from '../../img/solution/solution_hr_1.png';
import solutionImg_Hr_2 from '../../img/solution/solution_hr_2.png';
import solutionImg_Hr_3 from '../../img/solution/solution_hr_3.png';
import './Solution.css';
import { useTranslation } from 'react-i18next';
import crossbar from '../../img/crossbar.png';

export default function Solution(props) {
    const { t, } = useTranslation();

    return (
        <div id='solution' style={{ marginTop: 95,marginBottom:50 }} ref={props.refer}>
            <div>
                <Container>
                    <Row style={{ textAlign: 'center' }} className='web'>
                        <Col lg={12}>
                            <img src={crossbar} style={{width:'144px'}}/><br/><br/>
                            <h2 className='' style={{color:'#152C7C',fontSize:'32px'}} dangerouslySetInnerHTML = {{ __html:t('solution.title')}} ></h2>
                        </Col>
                    </Row>
                    <br/><br/>
                    <Row style={{fontFamily:'SVN-Gilroy'}}>
                        <Col> 
                            <div>
                                <img src={solutionImg_Hr_1} style={{width:'80px',height:'80px',marginLeft:'auto',marginRight:'auto',display:'block'}}/><br/>
                                <div style={{textAlign:'center',fontSize:'16px',fontFamily:'SVN-Gilroy',color:'#152C7C',fontWeight:'700',marginBottom:'8px'}}>1. Tự động hóa mọi nghiệp vụ nhân sự</div>
                                <ul>
                                    <li style={{textAlign:'justify'}}>Thư viện quản lý hàng triệu hồ sơ ứng viên một cách dễ dàng, tập trung: Hợp đồng lao động, thông tin cá nhân, lương thưởng</li>
                                    <li style={{textAlign:'justify'}}>Tự động kiểm soát các thủ tục nhân sự: Nhân viên hết hạn hợp đồng, sinh nhật, quá trình công tác, khen thưởng, kỷ luật</li>
                                    <li style={{textAlign:'justify'}}>Báo cáo tự động tình hình nhân sự công ty: Số lượng nhân sự phòng ban, tình hình biến động nhân sự,...</li>
                                </ul>
                            </div>
                        </Col>
                        <Col> 
                            <div>
                                <img src={solutionImg_Hr_2} style={{width:'80px',height:'80px',marginLeft:'auto',marginRight:'auto',display:'block'}}/><br/>
                                <div style={{textAlign:'center',fontSize:'16px',fontFamily:'SVN-Gilroy',color:'#152C7C',fontWeight:'700',marginBottom:'8px'}}>2. Chấm công - tính lương tự động, tức thời</div>
                                <ul>
                                    <li style={{textAlign:'justify'}}>Đa dạng phương thức ghi nhận chấm công: Tích hợp hệ thống máy chấm công, import chấm công từ excel, nhập thủ công,...</li>
                                    <li style={{textAlign:'justify'}}>Tự động xử lý công, tính lương từ bảng công và xử lý các khoản trích nộp theo quy định nhà nước </li>
                                    <li style={{textAlign:'justify'}}>Quản lý tình hình trả lương, theo dõi công nợ, phúc lợi</li>
                                </ul>
                            </div>
                        </Col>
                        <Col> 
                            <div>
                                <img src={solutionImg_Hr_3} style={{width:'80px',height:'80px',marginLeft:'auto',marginRight:'auto',display:'block'}}/><br/>
                                <div style={{textAlign:'center',fontSize:'16px',fontFamily:'SVN-Gilroy',color:'#152C7C',fontWeight:'700',marginBottom:'8px'}}>3. Quản lý doanh nghiệp</div>
                                <ul>
                                    <li style={{textAlign:'justify'}}>Thiết lập công ty, phòng ban, chức vụ</li>
                                    <li style={{textAlign:'justify'}}>Kiểm soát thời gian làm việc</li>
                                    <li style={{textAlign:'justify'}}>Quản lý kỳ nghỉ, phụ cấp</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}